import Vue from 'vue';

import {
  BVConfigPlugin,
  BadgePlugin,
  ButtonPlugin,
  CardPlugin,
  DropdownPlugin,
  FormCheckboxPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormRadioPlugin,
  FormTextareaPlugin,
  ImagePlugin,
  InputGroupPlugin,
  JumbotronPlugin,
  LayoutPlugin,
  LinkPlugin,
  ListGroupPlugin,
  ModalPlugin,
  NavbarPlugin,
  SidebarPlugin,
  ToastPlugin,
  TooltipPlugin
} from 'bootstrap-vue';

Vue.use(BVConfigPlugin, {
  "BTooltip": {
    "delay": {
      "show": 300,
      "hide": 50
    }
  }
});

Vue.use(BadgePlugin);
Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(DropdownPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(ImagePlugin);
Vue.use(InputGroupPlugin);
Vue.use(JumbotronPlugin);
Vue.use(LayoutPlugin);
Vue.use(LinkPlugin);
Vue.use(ListGroupPlugin);
Vue.use(ModalPlugin);
Vue.use(NavbarPlugin);
Vue.use(SidebarPlugin);
Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);
