import createHttpError from 'http-errors';

// Keys are deprecated CTF gallery content entry slugs. Values are Europeana
// Set API numeric IDs.
const REDIRECTS = {
  'highlights-of-swedish-art': 8950,
  'highlights-of-spanish-art': 8951,
  'highlights-of-slovenian-art': 8952,
  'highlights-of-art-from-the-united-kingdom': 8953,
  'highlights-of-art-from-portugal': 8954,
  'highlights-of-art-from-poland': 8955,
  'highlights-of-art-from-norway': 8956,
  'highlights-of-art-from-the-czech-republic': 8957,
  'highlights-of-art-from-malta': 8958,
  'highlights-of-art-from-cyprus': 8959,
  'highlights-of-art-from-lithuania': 8960,
  'highlights-of-danish-art': 8961,
  'music-in-paintings': 8962,
  'highlights-of-art-from-latvia': 8963,
  'highlights-of-estonian-art': 8964,
  'italian-masterpieces': 8965,
  'highlights-of-art-from-ireland': 8966,
  'highlights-of-finnish-art': 8967,
  'highlights-of-hungarian-art': 8968,
  'highlights-of-art-from-greece': 8969,
  'highlights-of-art-from-germany': 8970,
  'highlights-of-art-from-romania': 8971,
  'highlights-of-french-art': 8972,
  'lithuanian-opera-costumes': 8973,
  'highlights-of-art-from-belgium': 8974,
  'harpischords': 8975,
  'highlights-of-art-from-the-netherlands': 8976,
  'guitars': 8977,
  'accordion-players': 8978,
  'sportswear': 8979,
  'winterwear': 8980,
  'art-by-female-artists': 8981,
  'art-nouveau-jewellery': 8982,
  'art-nouveau-ceramics': 8983,
  'art-nouveau-glassware': 8984,
  'lamellophones': 8985,
  'self-portraits': 8986,
  'the-hurdy-gurdy': 8987,
  'surimono-the-finest-japanese-prints': 8988,
  'monstrous-musical-instruments': 8989,
  'art-nouveau-posters': 8990,
  'european-landscapes-and-landmarks': 8991,
  'the-magic-lantern': 8992,
  'healthy-body': 8993,
  'tea-time': 8994,
  'wilhelm-weimar': 8995,
  'thank-you-for-the-music': 8996,
  'parrots': 8997,
  'heinrich-hamann': 8998,
  'take-a-bow': 8999,
  'pluriarcs': 9000,
  'arched-harps': 9001,
  'dragons-in-myth-and-science': 9002,
  'cats': 9003,
  'stained-glass': 9004,
  'european-folk-costumes': 9005,
  'seascapes': 9006,
  'treasures-of-the-mauritshuis': 9007,
  'rivers-and-canals': 9008,
  'animals': 9009,
  'calligraphy-across-cultures': 9010,
  'planes-trains-and-automobiles': 9011,
  'holiday-snapshots': 9012,
  'winter-wonderland': 9013,
  'skylines-and-panoramas': 9014,
  'barrel-organs': 9015,
  'famous-migrants': 9016,
  'calenders': 9017,
  'albert-edelfelt': 9018,
  'jazz-greats': 9019,
  'jazz-in-concert': 9020,
  'from-balloon-to-blimp-staying-afloat': 9021,
  'winter-sports': 9022,
  'hats-and-headgear': 9023,
  'gal-pals': 9024,
  'women-in-world-war-i': 9025,
  '1914-1918-serbia': 9026,
  '1914-1918-italy': 9027,
  '1914-1918-luxembourg': 9028,
  'early-photographs-of-japanese-musicians': 9029,
  'lisbon': 9030,
  'spring': 9031,
  'keyboards': 9032,
  'migration-writing-home': 9033,
  'children-reading': 9034,
  'budapest': 9035,
  'leeuwarden': 9036,
  'early-depictions-of-musical-instruments': 9037,
  'tattoos': 9038,
  'journeys-of-migration': 9039,
  'rainbows': 9040,
  'field-postcards-1914-1918': 9041,
  'utagawa-hiroshige': 9042,
  '1914-1918-hungary': 9043,
  'trading-places-migration-and-new-businesses': 9044,
  'autumn-in-art': 9045,
  'autochrome': 9046,
  'everyday-treasures': 9047,
  'wwi-propaganda-postcards': 9048,
  'book-covers': 9049,
  'party-in-europeana': 9050,
  'lyre-lyre': 9051,
  'archaeologists-at-work': 9052,
  'illuminated-initials': 9053,
  'death-and-burial': 9054,
  'pink': 9055,
  'alphabets': 9056,
  'handwritten-notes-in-books': 9057,
  'corsets': 9058,
  'silhouettes-a-century-of-female-fashion': 9059,
  'romani-people-in-europe': 9060,
  'parenting-in-art': 9061,
  'voting-in-elections': 9062,
  'postage-stamps': 9063,
  'endangered-species': 9064,
  'ohara-koson': 9065,
  'cooking-equipment': 9066,
  'reading-newspapers': 9067,
  'group-portraits': 9068,
  'hot-air-balloons': 9069,
  'ice-cream': 9070,
  'summer-holidays-in-the-past': 9071,
  'aerial-photography': 9072,
  'synagogues': 9073,
  'albrecht-durer': 9074,
  'the-artists-studio': 9075,
  'working-lives-across-europe': 9076,
  'rugby-in-europe-in-the-past-and-present': 9077,
  'industrial-architecture-across-europe': 9078,
  'travel-and-tourism-ephemera': 9079,
  'telephones-through-time': 9080,
  'grand-hotel-europe': 9081,
  'ports-and-harbours': 9082,
  'art-and-industrial-heritage-1': 9083,
  'textile-industry': 9084,
  'fishing': 9085,
  'mining-and-miners': 9086,
  'women-at-work': 9087,
  'the-industry-of-making-war-the-kuk-kriegspressequartier': 9088,
  'europe-at-work-during-world-war-one': 9089,
  'lighthouses-across-europe': 9090,
  'children-at-work-images-of-child-labour': 9091,
  'baptism-and-baptisteries-in-the-byzantine-era': 9092,
  'byzantine-wall-paintings-in-kastoria-greece': 9093,
  'depictions-of-the-virgin-in-byzantine-art': 9094,
  'birds-of-the-byzantine': 9095,
  'good-and-evil-in-byzantine-art': 9096,
  'portraits-of-emperor-justinian-i': 9097,
  'masterpieces-of-byzantine-mosaic-st-saviour-in-chora': 9098,
  'strikes-and-industrial-protests': 9099,
  'in-the-factory': 9100,
  'pollution-an-industrial-legacy': 9101,
  'generating-electricity-and-power': 9102,
  'the-office': 9103,
  'migration-in-artworks': 9104,
  'o-christmas-tree': 9105,
  'board-games': 9106,
  'playing-cards': 9107,
  'disabilities-in-art': 9108,
  'blue': 9109,
  'carnival-celebrations': 9110,
  'rats': 9111,
  'weddings-and-marriages': 9112,
  'green': 9113,
  'lgbtq-plus-lives-and-queer-heritage': 9114,
  'growing-up-black': 9115,
  'lgbtq-plus-artists-and-art': 9116,
  'washing-your-hands': 9117,
  'julia-margaret-cameron': 9118,
  'suffragettes': 9119,
  'playing-games': 9120,
  'easter-eggs': 9121,
  'hermits': 9122,
  'toilet-paper': 9123,
  'equal-pay': 9124,
  'maps-of-europe': 9125,
  'mansplaining-in-art': 9126,
  'four-leaf-clover': 9127,
  'interesting-interiors': 9128,
  'abstract-art': 9129,
  'tranquil-spaces': 9130,
  'performers-from-the-eurovision-song-contest': 9131,
  'beautiful-views': 9132,
  'vintage-films-of-sport-championships': 9133,
  'spring-flowers': 9134,
  'trees-in-art': 9135,
  'endpapers': 9136,
  'heroes-of-medicine': 9137,
  'the-great-indoors': 9138,
  'hairdressers-barbers-and-hairstyles': 9139,
  'papyrus-paper-of-antiquity': 9140,
  'utopians-and-dystopians-of-all-time': 9141,
  'bees-and-beekeeping': 9142,
  'cycling': 9143,
  'pride-parades-and-protests': 9144,
  'views-of-stockholm': 9145,
  'views-of-venice': 9146,
  'views-of-dublin': 9147,
  'youve-got-mail': 9148,
  'the-french-riviera': 9149,
  'camping': 9150,
  'norwegian-fjords': 9151,
  'the-greek-islands': 9152,
  'castles-and-palaces': 9153,
  'open-air-and-living-history-museums': 9154,
  'people-in-the-movement-against-anti-black-racism': 9155,
  'black-musicians-who-changed-music-forever': 9156,
  'postcards-from-europe': 9157,
  'bergamo': 9158,
  'mountain-scenery': 9159,
  'midsummer': 9160,
  'european-capitals-of-culture': 9161,
  'sanctuaries-and-ritual-practices-of-the-iberians': 9162,
  'stone-in-religion-and-faiths': 9163,
  'caravanning': 9164,
  'travelling-in-style': 9165,
  'scouts-and-girl-guides': 9166,
  'amulets-from-around-the-world': 9167,
  'olympic-games-posters-and-graphic-design': 9168,
  'masks-and-head-coverings': 9169,
  'arenas-and-stadiums': 9170,
  'railway-journeys-from-the-past-on-film': 9171,
  'vintage-travelogue-films': 9172,
  'medals-and-trophies': 9173,
  'railway-stations-across-europe': 9174,
  'cityscapes-of-europe': 9175,
  'paris-city-of-art': 9176,
  'churches-and-cathedrals': 9177,
  'national-parks-of-europe': 9178,
  'architecture': 9179,
  'rotterdam': 9180,
  'rome': 9181,
  'berlin': 9182,
  'airports': 9183,
  'istanbul': 9184,
  'views-of-vienna': 9185,
  'iceland': 9186,
  'around-the-world-in-colourful-posters': 9187,
  'bridges-of-europe': 9188,
  'schools-in-the-past': 9189,
  'clouds': 9190,
  'windmills': 9191,
  'air-traffic-control': 9192,
  'rainy-weather': 9193,
  'the-human-body-as-marble-statuary': 9194,
  'foggy-weather': 9195,
  'stormy-weather': 9196,
  'sunny-weather': 9197,
  'rice-cultivation-in-china': 9198,
  'mandarins-of-china': 9199,
  'roman-metal-in-the-low-countries': 9200,
  'gandhi': 9201,
  'axeheads': 9202,
  'banquets-booze-and-beats-the-art-of-celebrating-in-antiquity': 9203,
  'jewellery-and-personal-adornment': 9204,
  'archaeological-tools-and-equipment': 9205,
  'textiles-in-the-pre-columbian-peru': 9206,
  'grave-goods-ikapes': 9207,
  'art-nouveau-in-danube-region': 9208,
  'ancient-boats-and-navigation': 9209,
  'archaeologists-explorers-researchers-and-painters': 9210,
  'obelisks-in-the-world': 9211,
  'etchings-of-roman-antiquities': 9212,
  'life-and-works-of-beethoven': 9213,
  'archaeological-sites-in-southeastern-romania': 9214,
  'scotland-and-the-scottish-highlands': 9215,
  'markets': 9216,
  'schiller-ode-to-joy': 9217,
  'sculpture-and-sport': 9218,
  'amphitheaters-in-ancient-times': 9219,
  'yin-and-yang': 9220,
  'festive-flapper-dresses': 9221,
  'skiing-and-ski-slopes': 9222,
  'christmas-markets': 9223,
  'fruits-from-china': 9224,
  'horses-in-art-equestrian-portraits': 9225,
  'newsreels-and-tv-news-about-vaccines': 9226,
  'female-authors': 9227,
  'mosaics': 9228,
  'chess': 9229,
  'oxen': 9230,
  'sports-posters': 9231,
  'nobel-prize-winning-women': 9232,
  'mosques-across-europe': 9233,
  'artworks-by-pierre-auguste-renoir': 9234,
  'vaenern-swedens-largest-lake': 9235,
  'pioneering-women-photographers': 9236,
  'archaeology-draft': 9237,
  'power-suits': 9238,
  'female-archaeologists': 9239,
  'kimono': 9240,
  'early-20th-century-sports-portraits': 9241,
  'cherry-blossoms': 9242,
  'masterpieces-of-world-literature': 9243,
  'having-fun-with-space': 9244,
  'football-teams-across-europe': 9245,
  'habima-theatre': 9246,
  'chinese-influences-western-fashion': 9247,
  'buddhist-heritage': 9248,
  'drag': 9249,
  'goesta-adrian-nilsson': 9250,
  'art-and-sport': 9251,
  'tennis': 9252,
  'surfing-and-windsurfing': 9253,
  'olympic-torches-and-relays': 9254,
  'athletics': 9255,
  'gymnastics': 9256,
  'sokol-movement': 9257,
  'sport-souvenirs-and-memorabilia': 9258,
  'street-art-and-graffiti': 9259,
  'church-fathers': 9260,
  'black-people-in-european-art': 9261,
  'anti-apartheid-movement': 9262,
  'dizzy-gillespie': 9263,
  'duke-ellington': 9264,
  'black-footballers': 9265,
  'women-sport-and-dictatorships': 9266,
  'the-bible-and-its-books': 9267,
  'under-the-mistletoe': 9268,
  'snowball-fights': 9269,
  'monastic-rules-and-similar-formative-texts': 9270,
  'female-literacy-in-the-middle-ages': 9271,
  'helene-schjerfbeck': 9272,
  'turin': 9273,
  'medieval-coins': 9274,
  'dining-al-fresco': 9275,
  'portraits-by-ramon-casas-i-carbo': 9276,
  'lithuanian-american-sports': 9277,
  'on-public-transport': 9278,
  'basketball': 9279,
  'yellow': 9280,
  'waterfalls': 9281,
  'outstanding-manuscripts-from-the-7th-11th-centuries': 9282,
  'outstanding-manuscripts-from-the-12th-13th-centuries': 9283,
  'outstanding-manuscripts-from-the-14th-century': 9284,
  'outstanding-manuscripts-from-the-15th-16th-century': 9285
};

const GONE = [
  'highlights-of-austrian-art',
  'test-gallery-marta',
  'celebrity-fashion',
  'highlights-of-art-from-croatia',
  'the-swinging-60s',
  'ballet',
  'choirs',
  'flamenco',
  'grand-concert-halls',
  'haute-couture',
  'swimwear',
  'paper-fashion',
  'fashion-from-the-great-war',
  'masculin-feminin',
  'sartorial-masculinity',
  'fashion-for-travel',
  'highlights-of-europeana-fashion',
  'animalier',
  'eccentric-fashion',
  'embroidery',
  'prints',
  'the-finnish-kantele',
  'colorblock',
  'clothes-for-the-ballroom',
  'fashion-forward',
  'flowergems',
  'the-gala',
  'the-europeana-don-giovanni-tour',
  'mediaeval-birds',
  'mediaeval-grotesques',
  'mediaeval-beasts',
  'the-music-of-machines',
  'rising-from-the-deep-sea-monsters',
  'valihas',
  'jazz-ephemera',
  'vilhelm-hammershoi',
  '1914-1918-belgian-refugees-in-the-netherlands',
  'beetles-and-butterflies-nature-of-turkestan-in-drawings',
  'ellis-island-gateway-to-the-united-states',
  'olga-boznanska',
  'maria-sibylla-merian',
  'elsa-schiaparelli',
  'vintage-chocolate-advertising',
  'polaroids',
  'postcards',
  'photographs-by-ernest-rude',
  'reading-and-writing-aids-in-the-middle-ages',
  'anna-boberg'
];

export default ({ route, redirect, error }) => {
  const routePathParts = route.path.split('/');
  if ((routePathParts[2] === 'galleries')) {
    if (REDIRECTS[routePathParts[3]]) {
      return redirect(`/${routePathParts[1]}/galleries/${REDIRECTS[routePathParts[3]]}-${routePathParts[3]}`);
    } else if (GONE.includes(routePathParts[3])) {
      error(createHttpError(410));
    }
  }
};
