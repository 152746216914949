import Auth from './auth'

import './middleware'

// Active schemes
import scheme_23514a38 from './schemes/oauth2.js'
import scheme_1dff9cb2 from './schemes/authScheme.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":true,"watchLoggedIn":true,"redirect":{"login":"/account/login","logout":false,"home":"/account","callback":"/account/callback"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/"}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"keycloak"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // _oauth2
  $auth.registerStrategy('_oauth2', new scheme_23514a38($auth, {"_name":"_oauth2"}))

  // keycloak
  $auth.registerStrategy('keycloak', new scheme_1dff9cb2($auth, {"_name":"keycloak"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
